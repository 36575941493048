<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_list border flex_box" @click="fnPage(0)">
                  <p>업체 정보</p>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(1)">
                  <p>주차 현황 표</p>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(2)">
                  <p>행동강령, 입주민 안내문</p>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(3)">
                  <p>관리비 산출 내역</p>
                  <div class="icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  name: 'menu-building-status-index',
  description: '빌라현황',
  setup() {
    const router = useRouter()

    const fnPage = (index = 0) => {
      /*
      0. 업체정보
      1. 주차 현황 표
      2. 행동강령, 입주민 안내문
      3. 관리비 산출 내역
       */
      router.push({
        path: '/menu/building/status/view',
        query: {
          type: index
        }
      })
    }
    return { fnPage }
  }
}
</script>

<style scoped></style>
